<template>
  <el-card shadow="never" class="aui-card--fill">
    <div class="mod-sys__post">
      <el-form :inline="true" :model="dataForm" @keyup.enter.native="getData">
        <el-form-item>
          <el-input
            v-model="dataForm.activateCode"
            placeholder="卡号"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-input
            v-model="dataForm.orderId"
            placeholder="订单号"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-input
            v-model="dataForm.productName"
            placeholder="商品名称"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-date-picker
            v-model="value1"
            type="daterange"
            range-separator="至"
            start-placeholder="激活开始日期"
            end-placeholder="激活结束日期"
            value-format="yyyy-MM-dd"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-date-picker
            v-model="value2"
            type="daterange"
            range-separator="至"
            start-placeholder="购买开始日期"
            end-placeholder="购买结束日期"
            value-format="yyyy-MM-dd"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button @click="getData" type="primary">查询</el-button>
        </el-form-item>
        <el-form-item>
          <el-button @click="exportsData">导出</el-button>
        </el-form-item>
      </el-form>

      <el-table
        v-loading="dataListLoading"
        :data="dataList"
        border
        style="width: 100%"
      >
        <el-table-column
          prop="orderId"
          label="订单ID"
          header-align="center"
          align="center"
          width="150"
        >
        </el-table-column>

        <el-table-column
          prop="activateCode"
          label="卡号"
          header-align="center"
          align="center"
        ></el-table-column>

        <el-table-column
          prop="productName"
          label="商品名称"
          header-align="center"
          align="center"
          width="150"
        >
        </el-table-column>
        <el-table-column
          prop="createDate"
          label="购买日期"
          header-align="center"
          align="center"
        ></el-table-column>

        <el-table-column
          prop="activateDate"
          label="激活日期"
          header-align="center"
          align="center"
        ></el-table-column>

        <el-table-column
          prop="mobile"
          label="激活手机号"
          header-align="center"
          align="center"
        >
        </el-table-column>

        <el-table-column
          prop="appCode"
          label="应用CODE"
          header-align="center"
          align="center"
        >
        </el-table-column>

        <el-table-column
          prop="userType"
          label="用户类型"
          header-align="center"
          align="center"
        >
        </el-table-column>
      </el-table>
      <el-pagination
        :current-page="page"
        :page-size="10"
        :total="total"
        layout="total, prev, pager, next"
        @current-change="pageCurrentChangeHandle"
      >
      </el-pagination>
    </div>
  </el-card>
</template>

<script>
import download from './download';

const urls = {
  page: '/pay/order/cardActivationPage',
  exports: '/pay/order/cardActivationExport',
};

export default {
  data() {
    return {
      value1: [],
      value2: [],
      //头部表单筛选
      dataForm: {
        activateCode: '',
        orderId: '',
        productName: '',
        activeEndDate: '',
        activeStartDate: '',
        buyEndDate: '',
        buyStartDate: '',
      },
      dataListLoading: false,
      page: 1,
      total: 0,
      dataList: [],
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      let params = this.formateParams();
      console.log(params);
      this.dataListLoading = true;
      const res = await this.$http.get(urls.page, { params });
      this.dataListLoading = false;
      this.total = res.data.data.total;
      this.dataList = res.data.data.list;
    },

    async exportsData() {
      let params = this.formateParams('exports');
      download(urls.exports, params, '卡激活');
    },

    formateParams(type) {
      let {
        activateCode,
        orderId,
        productName,
        activeEndDate,
        activeStartDate,
        buyEndDate,
        buyStartDate,
      } = this.dataForm;
      if (this.value1 && this.value1.length) {
        activeStartDate = this.value1[0];
        activeEndDate = this.value1[1];
      }
      if (this.value2 && this.value2.length) {
        buyStartDate = this.value2[0];
        buyEndDate = this.value2[1];
      }
      if (type) {
        return {
          activeEndDate,
          activeStartDate,
          buyEndDate,
          buyStartDate,
          activateCode,
          orderId,
          productName,
        };
      }
      return {
        limit: 10,
        page: this.page,
        activeEndDate,
        activeStartDate,
        buyEndDate,
        buyStartDate,
        activateCode,
        orderId,
        productName,
      };
    },
    pageCurrentChangeHandle(page) {
      this.page = page;
      this.getData();
    },
    // unset(obj) {
    //   for (const key in obj) {
    //     if (obj[key] === '') delete obj[key];
    //   }
    //   return obj;
    // },
    //重置表单
    // resetHandle() {
    //   //Vue中重置data的数据为初始状态
    //   Object.assign(this.$data, this.$options.data());
    //   this.getDataList();
    // },
  },
};
</script>
